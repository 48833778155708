import { createContext, useRef, useState } from "react";
import rosetta from "rosetta";
// import rosetta from 'rosetta/debug';

import {
  ContactUnit,
  FormOfAddress,
  LinkType,
  MainUsefulAreaTypes,
  MainLanguage,
  Phase,
  Region,
  Status,
} from "./graphql";
import de from "./locales/de.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";

const enumSortMap: {
  [key: string]: { [key: string]: number };
} = {
  contactUnit: {
    [ContactUnit.Person]: 0,
    [ContactUnit.Organisation]: 1,
  },
  formOfAddress: {
    [FormOfAddress.Madame]: 0,
    [FormOfAddress.Sir]: 1,
    [FormOfAddress.Empty]: 2,
  },
  linkType: {
    [LinkType.DirectLink]: 0,
    [LinkType.VirtualTour]: 1,
    [LinkType.Video]: 2,
    [LinkType.Webcam]: 4,
  },
  mainLanguage: {
    [MainLanguage.De]: 0,
    [MainLanguage.Fr]: 1,
    [MainLanguage.It]: 2,
  },
  mainUsefulAreaType: {
    [MainUsefulAreaTypes.Habitation]: 0,
    [MainUsefulAreaTypes.Office]: 1,
    [MainUsefulAreaTypes.Retail]: 2,
    [MainUsefulAreaTypes.Gastronomy]: 3,
    [MainUsefulAreaTypes.Health]: 4,
    [MainUsefulAreaTypes.Education]: 5,
    [MainUsefulAreaTypes.Service]: 6,
    [MainUsefulAreaTypes.Storage]: 7,
  },
  phase: {
    [Phase.Study]: 0,
    [Phase.Competition]: 1,
    [Phase.SpecialPurposePlanning]: 2,
    [Phase.PreProject]: 3,
    [Phase.BuildingProject]: 4,
    [Phase.Construction]: 5,
    [Phase.Management]: 6,
    [Phase.ReadyToMoveInto]: 7,
  },
  region: {
    [Region.Center]: 0,
    [Region.East]: 1,
    [Region.West]: 2,
  },
  status: {
    [Status.Active]: 0,
    [Status.Inactive]: 1,
  },
};

type LocaleType = "de" | "fr" | "it";
type ContextType = {
  activeLocale: LocaleType;
  enumToOptions: (e: object, p: string) => { label: string; value: string }[];
  getLocaleTitle: (titleData: TitleDataType) => string;
  pluralRules: Intl.PluralRules;
} & typeof i18n;

const i18n = rosetta({ de, fr, it });

export const defaultLanguage: LocaleType = "de";
i18n.locale(defaultLanguage);

function enumToOptions(appEnum: object, prefix: string) {
  return Object.values(appEnum)
    .map((value) => ({ label: i18n.t(`${prefix}.${value}`), value }))
    .sort((a, b) => {
      if (enumSortMap[prefix]) {
        return enumSortMap[prefix][a.value] - enumSortMap[prefix][b.value];
      }
      return a.label.localeCompare(b.label);
    });
}

function getPluralRules(lang = defaultLanguage) {
  return new Intl.PluralRules(`${lang}-CH`);
}

export const I18nContext = createContext<ContextType>({
  ...i18n,
  activeLocale: defaultLanguage,
  enumToOptions,
  getLocaleTitle: (titleData: TitleDataType) => titleData?.titleDe,
  pluralRules: getPluralRules(),
});

type TitleDataType = {
  titleDe: string;
  titleFr: string;
  titleIt: string;
};

export default function I18n({ children }: { children: React.ReactNode }) {
  const activeLocaleRef = useRef<LocaleType>(defaultLanguage);
  const pluralRulesRef = useRef<Intl.PluralRules>(getPluralRules());
  const [, setTick] = useState(0);

  const i18nWrapper = {
    ...i18n,
    activeLocale: activeLocaleRef.current,
    enumToOptions,
    getLocaleTitle: (titleData: TitleDataType) => {
      if (activeLocaleRef.current === "de") return titleData?.titleDe;
      else if (activeLocaleRef.current === "fr") return titleData?.titleFr;
      else if (activeLocaleRef.current === "it") return titleData?.titleIt;
      else return "";
    },
    locale: (lang: LocaleType) => {
      activeLocaleRef.current = lang;
      pluralRulesRef.current = getPluralRules(lang);
      setTick((tick) => tick + 1); // force rerender to update view
      return i18n.locale(lang);
    },
    pluralRules: pluralRulesRef.current,
  };

  return (
    <I18nContext.Provider value={i18nWrapper}>{children}</I18nContext.Provider>
  );
}
