import { createContext, useContext } from "react";

import Tab from "./sbb/Tab";

type Props = {
  children: (language: string) => React.ReactNode;
  "data-cy"?: string;
  hasError?: { de?: boolean; fr?: boolean; it?: boolean; en?: boolean };
};

type LanguageTabsContextType = {
  languageTab: number;
  setLanguageTab: (index: number) => void;
};

export const LanguageTabsContext = createContext<LanguageTabsContextType>({
  languageTab: 0,
  setLanguageTab: () => {},
});

export default function LanguageTabs({ children, hasError, ...props }: Props) {
  const { languageTab, setLanguageTab } = useContext(LanguageTabsContext);

  return (
    <Tab.Group {...props} selectedIndex={languageTab} onChange={setLanguageTab}>
      <Tab label="DE" hasError={hasError?.["de"]}>
        {children("De")}
      </Tab>
      <Tab label="FR" hasError={hasError?.["fr"]}>
        {children("Fr")}
      </Tab>
      <Tab label="IT" hasError={hasError?.["it"]}>
        {children("It")}
      </Tab>
      <Tab label="EN" hasError={hasError?.["en"]}>
        {children("En")}
      </Tab>
    </Tab.Group>
  );
}
