export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any };
  /** A geographical object that contains multiple polygons. */
  MultiPolygon: { input: any; output: any };
  /** Represents a point as `(x, y, z)` or `(x, y)`. */
  Point: { input: any; output: any };
  Upload: { input: any; output: any };
};

/** Acquisition(id, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, project_id, updated_at, updated_by, report_timestamp, property, factsheet_left_upper, factsheet_right_upper, factsheet_lower) */
export type Acquisition = {
  __typename?: "Acquisition";
  availableAreas: Array<AvailableArea>;
  claimDe: Scalars["String"]["output"];
  claimEn: Scalars["String"]["output"];
  claimFr: Scalars["String"]["output"];
  claimIt: Scalars["String"]["output"];
  expansionRentableAreas: Array<ExpansionRentableArea>;
  factsheetImages: FactsheetPositions;
  floorAreas: Array<FloorArea>;
  id: Scalars["ID"]["output"];
  leadDe: Scalars["String"]["output"];
  leadEn: Scalars["String"]["output"];
  leadFr: Scalars["String"]["output"];
  leadIt: Scalars["String"]["output"];
  projectId?: Maybe<Scalars["String"]["output"]>;
  property: Property;
  reportTimestamp: Scalars["DateTime"]["output"];
  titleDe: Scalars["String"]["output"];
  titleEn: Scalars["String"]["output"];
  titleFr: Scalars["String"]["output"];
  titleIt: Scalars["String"]["output"];
};

/** Acquisition(id, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, project_id, updated_at, updated_by, report_timestamp, property, factsheet_left_upper, factsheet_right_upper, factsheet_lower) */
export type AcquisitionPartial = {
  availableAreas?: InputMaybe<Array<AvailableAreaInput>>;
  claimDe?: InputMaybe<Scalars["String"]["input"]>;
  claimEn?: InputMaybe<Scalars["String"]["input"]>;
  claimFr?: InputMaybe<Scalars["String"]["input"]>;
  claimIt?: InputMaybe<Scalars["String"]["input"]>;
  expansionRentableAreas?: InputMaybe<Array<ExpansionRentableAreaInput>>;
  floorAreas?: InputMaybe<Array<FloorAreaInput>>;
  leadDe?: InputMaybe<Scalars["String"]["input"]>;
  leadEn?: InputMaybe<Scalars["String"]["input"]>;
  leadFr?: InputMaybe<Scalars["String"]["input"]>;
  leadIt?: InputMaybe<Scalars["String"]["input"]>;
  projectId?: InputMaybe<Scalars["String"]["input"]>;
  propertyId: Scalars["ID"]["input"];
  reportTimestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  titleDe?: InputMaybe<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleFr?: InputMaybe<Scalars["String"]["input"]>;
  titleIt?: InputMaybe<Scalars["String"]["input"]>;
};

export type Asset = {
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type AssetFilter = {
  AND?: InputMaybe<AssetFilter>;
  NOT?: InputMaybe<AssetFilter>;
  OR?: InputMaybe<AssetFilter>;
  assetTags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fileType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  searchDe?: InputMaybe<Scalars["String"]["input"]>;
  searchEn?: InputMaybe<Scalars["String"]["input"]>;
  searchFr?: InputMaybe<Scalars["String"]["input"]>;
  searchIt?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssetList = {
  __typename?: "AssetList";
  assets: Array<RawAsset>;
};

export type AssetOrder = {
  altTextDe?: InputMaybe<Ordering>;
  altTextEn?: InputMaybe<Ordering>;
  altTextFr?: InputMaybe<Ordering>;
  altTextIt?: InputMaybe<Ordering>;
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type AssetPartial = {
  altTextDe?: InputMaybe<Scalars["String"]["input"]>;
  altTextEn?: InputMaybe<Scalars["String"]["input"]>;
  altTextFr?: InputMaybe<Scalars["String"]["input"]>;
  altTextIt?: InputMaybe<Scalars["String"]["input"]>;
  assetTags?: InputMaybe<Array<AssetTagInput>>;
  displayCategory?: InputMaybe<DisplayCategory>;
  factsheetLarge?: InputMaybe<FactsheetLargeInput>;
  factsheetLeft?: InputMaybe<FactsheetLeftInput>;
  file?: InputMaybe<Scalars["Upload"]["input"]>;
  fileType?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  idInImport?: InputMaybe<Scalars["String"]["input"]>;
  keyVisual?: InputMaybe<KeyVisualInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  teaser?: InputMaybe<TeaserInput>;
};

/** AssetTag(id, name, id_in_import) */
export type AssetTag = {
  __typename?: "AssetTag";
  name: Scalars["String"]["output"];
};

/** AssetTag(id, name, id_in_import) */
export type AssetTagInput = {
  name: Scalars["String"]["input"];
};

export type AssetsInput = {
  files: Array<Scalars["Upload"]["input"]>;
};

/** AvailableArea(id, name_de, name_fr, name_it, name_en, square_meters, acquisition, updated_at, updated_by) */
export type AvailableArea = {
  __typename?: "AvailableArea";
  id: Scalars["ID"]["output"];
  nameDe: Scalars["String"]["output"];
  nameEn: Scalars["String"]["output"];
  nameFr: Scalars["String"]["output"];
  nameIt: Scalars["String"]["output"];
  squareMeters?: Maybe<Scalars["Int"]["output"]>;
};

/** AvailableArea(id, name_de, name_fr, name_it, name_en, square_meters, acquisition, updated_at, updated_by) */
export type AvailableAreaInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  nameDe?: InputMaybe<Scalars["String"]["input"]>;
  nameEn?: InputMaybe<Scalars["String"]["input"]>;
  nameFr?: InputMaybe<Scalars["String"]["input"]>;
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  squareMeters?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Contact(id, unit, form_of_address, name, last_name, company, address_supplement, email, phone, mobile, website, street, street_number, zip_code, city, canton, country, status, csv_id, created_at) */
export type Contact = {
  __typename?: "Contact";
  addressSupplement: Scalars["String"]["output"];
  canton: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  company: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  csvId?: Maybe<Scalars["Int"]["output"]>;
  email: Scalars["String"]["output"];
  formOfAddress: FormOfAddress;
  hasProperty: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  mobile: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
  status: Status;
  street: Scalars["String"]["output"];
  streetNumber: Scalars["String"]["output"];
  unit: ContactUnit;
  website: Scalars["String"]["output"];
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

/** Contact(id, unit, form_of_address, name, last_name, company, address_supplement, email, phone, mobile, website, street, street_number, zip_code, city, canton, country, status, csv_id, created_at) */
export type ContactFilter = {
  AND?: InputMaybe<ContactFilter>;
  NOT?: InputMaybe<ContactFilter>;
  OR?: InputMaybe<ContactFilter>;
  formOfAddress?: InputMaybe<FormOfAddress>;
  hasProperty?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Status>;
  unit?: InputMaybe<ContactUnit>;
};

/** Contact(id, unit, form_of_address, name, last_name, company, address_supplement, email, phone, mobile, website, street, street_number, zip_code, city, canton, country, status, csv_id, created_at) */
export type ContactInput = {
  addressSupplement?: InputMaybe<Scalars["String"]["input"]>;
  canton?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  company?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  csvId?: InputMaybe<Scalars["Int"]["input"]>;
  email: Scalars["String"]["input"];
  formOfAddress?: InputMaybe<FormOfAddress>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Status>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  streetNumber?: InputMaybe<Scalars["String"]["input"]>;
  unit?: InputMaybe<ContactUnit>;
  website?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactOrder = {
  company?: InputMaybe<Ordering>;
  lastName?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Contact(id, unit, form_of_address, name, last_name, company, address_supplement, email, phone, mobile, website, street, street_number, zip_code, city, canton, country, status, csv_id, created_at) */
export type ContactPartial = {
  addressSupplement?: InputMaybe<Scalars["String"]["input"]>;
  canton?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  company?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  csvId?: InputMaybe<Scalars["Int"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  formOfAddress?: InputMaybe<FormOfAddress>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Status>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  streetNumber?: InputMaybe<Scalars["String"]["input"]>;
  unit?: InputMaybe<ContactUnit>;
  website?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** ContactType(id, name, is_public, sort_index) */
export type ContactType = {
  __typename?: "ContactType";
  id: Scalars["ID"]["output"];
  isPublic: Scalars["Boolean"]["output"];
  name: ContactTypeName;
  sortIndex: Scalars["Int"]["output"];
};

export enum ContactTypeName {
  Acquisition = "ACQUISITION",
  AcquisitionOffice = "ACQUISITION_OFFICE",
  AcquisitionRetailAndGastronomy = "ACQUISITION_RETAIL_AND_GASTRONOMY",
  Architect = "ARCHITECT",
  CityPlanner = "CITY_PLANNER",
  Contact = "CONTACT",
  Engineer = "ENGINEER",
  GeneralContractor = "GENERAL_CONTRACTOR",
  GeneralPlanner = "GENERAL_PLANNER",
  Investor = "INVESTOR",
  Marketer = "MARKETER",
  ProjectManager = "PROJECT_MANAGER",
  RealEstateManager = "REAL_ESTATE_MANAGER",
  Sales = "SALES",
  TeamLeader = "TEAM_LEADER",
  TotalContractor = "TOTAL_CONTRACTOR",
}

export enum ContactUnit {
  Organisation = "ORGANISATION",
  Person = "PERSON",
}

export type CreateAssetsPayload = AssetList | OperationInfo;

export type CreateContactPayload = Contact | OperationInfo;

export type CreatePropertyPayload = OperationInfo | Property;

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type DeleteAssetInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DeleteAssetPayload = OperationInfo | RawAsset;

/** Contact(id, unit, form_of_address, name, last_name, company, address_supplement, email, phone, mobile, website, street, street_number, zip_code, city, canton, country, status, csv_id, created_at) */
export type DeleteContactInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DeleteContactPayload = Contact | OperationInfo;

/** Property(id, status, publish_on_website, start_of_construction, move_into, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, import_key, project_id, phase, portfolio, region, main_language, economic_unit, land_area, total_area, area_zone_de, area_zone_fr, area_zone_it, area_zone_en, equity, investment_volume, street, street_number, zip_code, city, url_freebrand_website, url_brand_video, updated_at, updated_by, geom_area, geom_point, map_zoom, factsheet_left_upper, factsheet_right_upper, factsheet_lower, report_timestamp) */
export type DeletePropertyInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type DeletePropertyPayload = OperationInfo | Property;

export enum DisplayCategory {
  Brochure = "BROCHURE",
  CiCd = "CI_CD",
  Logo = "LOGO",
}

export type DjangoFileType = {
  __typename?: "DjangoFileType";
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
};

/** ExpansionRentableArea(id, name_de, name_fr, name_it, name_en, expansion_de, expansion_fr, expansion_it, expansion_en, acquisition, updated_at, updated_by) */
export type ExpansionRentableArea = {
  __typename?: "ExpansionRentableArea";
  expansionDe: Scalars["String"]["output"];
  expansionEn: Scalars["String"]["output"];
  expansionFr: Scalars["String"]["output"];
  expansionIt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  nameDe: Scalars["String"]["output"];
  nameEn: Scalars["String"]["output"];
  nameFr: Scalars["String"]["output"];
  nameIt: Scalars["String"]["output"];
};

/** ExpansionRentableArea(id, name_de, name_fr, name_it, name_en, expansion_de, expansion_fr, expansion_it, expansion_en, acquisition, updated_at, updated_by) */
export type ExpansionRentableAreaInput = {
  expansionDe?: InputMaybe<Scalars["String"]["input"]>;
  expansionEn?: InputMaybe<Scalars["String"]["input"]>;
  expansionFr?: InputMaybe<Scalars["String"]["input"]>;
  expansionIt?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  nameDe?: InputMaybe<Scalars["String"]["input"]>;
  nameEn?: InputMaybe<Scalars["String"]["input"]>;
  nameFr?: InputMaybe<Scalars["String"]["input"]>;
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
};

/** FactsheetLarge(id, x, y, width, height, rotation, asset) */
export type FactsheetLarge = {
  __typename?: "FactsheetLarge";
  height: Scalars["Int"]["output"];
  rotation: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
  x: Scalars["Int"]["output"];
  y: Scalars["Int"]["output"];
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type FactsheetLargeAsset = Asset & {
  __typename?: "FactsheetLargeAsset";
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** FactsheetLarge(id, x, y, width, height, rotation, asset) */
export type FactsheetLargeInput = {
  height: Scalars["Int"]["input"];
  rotation: Scalars["Int"]["input"];
  width: Scalars["Int"]["input"];
  x: Scalars["Int"]["input"];
  y: Scalars["Int"]["input"];
};

/** FactsheetLeft(id, x, y, width, height, rotation, asset) */
export type FactsheetLeft = {
  __typename?: "FactsheetLeft";
  height: Scalars["Int"]["output"];
  rotation: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
  x: Scalars["Int"]["output"];
  y: Scalars["Int"]["output"];
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type FactsheetLeftAsset = Asset & {
  __typename?: "FactsheetLeftAsset";
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** FactsheetLeft(id, x, y, width, height, rotation, asset) */
export type FactsheetLeftInput = {
  height: Scalars["Int"]["input"];
  rotation: Scalars["Int"]["input"];
  width: Scalars["Int"]["input"];
  x: Scalars["Int"]["input"];
  y: Scalars["Int"]["input"];
};

export type FactsheetPositions = {
  __typename?: "FactsheetPositions";
  leftUpper?: Maybe<FactsheetLeftAsset>;
  lower?: Maybe<FactsheetLargeAsset>;
  rightUpper?: Maybe<FactsheetLargeAsset>;
};

export type FactsheetPositionsPartial = {
  leftUpper?: InputMaybe<Scalars["ID"]["input"]>;
  lower?: InputMaybe<Scalars["ID"]["input"]>;
  rightUpper?: InputMaybe<Scalars["ID"]["input"]>;
};

export type FactsheetUris = {
  __typename?: "FactsheetUris";
  de: Scalars["String"]["output"];
  en: Scalars["String"]["output"];
  fr: Scalars["String"]["output"];
  it: Scalars["String"]["output"];
};

/** FloorArea(id, name_de, name_fr, name_it, name_en, square_meters, acquisition, updated_at, updated_by) */
export type FloorArea = {
  __typename?: "FloorArea";
  id: Scalars["ID"]["output"];
  nameDe: Scalars["String"]["output"];
  nameEn: Scalars["String"]["output"];
  nameFr: Scalars["String"]["output"];
  nameIt: Scalars["String"]["output"];
  squareMeters?: Maybe<Scalars["Int"]["output"]>;
};

/** FloorArea(id, name_de, name_fr, name_it, name_en, square_meters, acquisition, updated_at, updated_by) */
export type FloorAreaInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  nameDe?: InputMaybe<Scalars["String"]["input"]>;
  nameEn?: InputMaybe<Scalars["String"]["input"]>;
  nameFr?: InputMaybe<Scalars["String"]["input"]>;
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  squareMeters?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum FormOfAddress {
  Empty = "EMPTY",
  Madame = "MADAME",
  Sir = "SIR",
}

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type GalleryAsset = Asset & {
  __typename?: "GalleryAsset";
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** GalleryPicture(id, asset, index, property) */
export type GalleryPicture = {
  __typename?: "GalleryPicture";
  asset: GalleryAsset;
  id: Scalars["ID"]["output"];
  index: Scalars["Int"]["output"];
};

/** GalleryPicture(id, asset, index, property) */
export type GalleryPictureInput = {
  assetId: Scalars["ID"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index: Scalars["Int"]["input"];
};

/**
 * Groups are a generic way of categorizing users to apply permissions, or
 * some other label, to those users. A user can belong to any number of
 * groups.
 *
 * A user in a group automatically has all the permissions granted to that
 * group. For example, if the group 'Site editors' has the permission
 * can_edit_home_page, any user in that group will have that permission.
 *
 * Beyond permissions, groups are a convenient way to categorize users to
 * apply some label, or extended functionality, to them. For example, you
 * could create a group 'Special users', and you could write code that would
 * do special things to those users -- such as giving them access to a
 * members-only portion of your site, or sending them members-only email
 * messages.
 */
export type Group = {
  __typename?: "Group";
  name: Scalars["String"]["output"];
};

/** KeyVisual(id, x, y, width, height, rotation, asset) */
export type KeyVisual = {
  __typename?: "KeyVisual";
  height: Scalars["Int"]["output"];
  rotation: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
  x: Scalars["Int"]["output"];
  y: Scalars["Int"]["output"];
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type KeyVisualAsset = Asset & {
  __typename?: "KeyVisualAsset";
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** KeyVisual(id, x, y, width, height, rotation, asset) */
export type KeyVisualInput = {
  height: Scalars["Int"]["input"];
  rotation: Scalars["Int"]["input"];
  width: Scalars["Int"]["input"];
  x: Scalars["Int"]["input"];
  y: Scalars["Int"]["input"];
};

export type LatestUpdate = {
  __typename?: "LatestUpdate";
  at: Scalars["DateTime"]["output"];
  by?: Maybe<User>;
};

export enum LinkType {
  DirectLink = "DIRECT_LINK",
  Video = "VIDEO",
  VirtualTour = "VIRTUAL_TOUR",
  Webcam = "WEBCAM",
}

export enum MainLanguage {
  De = "DE",
  Fr = "FR",
  It = "IT",
}

/** MainUsefulArea(id, product, square_meters, property, updated_at, updated_by) */
export type MainUsefulArea = {
  __typename?: "MainUsefulArea";
  id: Scalars["ID"]["output"];
  product?: Maybe<MainUsefulAreaTypes>;
  squareMeters?: Maybe<Scalars["Int"]["output"]>;
};

/** MainUsefulArea(id, product, square_meters, property, updated_at, updated_by) */
export type MainUsefulAreaInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  product?: InputMaybe<MainUsefulAreaTypes>;
  squareMeters?: InputMaybe<Scalars["Int"]["input"]>;
};

/** MainUsefulArea(id, product, square_meters, property, updated_at, updated_by) */
export type MainUsefulAreaPartial = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  product?: InputMaybe<MainUsefulAreaTypes>;
  squareMeters?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum MainUsefulAreaTypes {
  Business = "BUSINESS",
  Education = "EDUCATION",
  Gastronomy = "GASTRONOMY",
  Habitation = "HABITATION",
  Health = "HEALTH",
  Misc = "MISC",
  Office = "OFFICE",
  Retail = "RETAIL",
  Service = "SERVICE",
  Storage = "STORAGE",
}

export type Mutation = {
  __typename?: "Mutation";
  createAssets: CreateAssetsPayload;
  createContact: CreateContactPayload;
  createProperty: CreatePropertyPayload;
  deleteAsset: DeleteAssetPayload;
  deleteContact: DeleteContactPayload;
  deleteProperty: DeletePropertyPayload;
  updateAsset: UpdateAssetPayload;
  updateContact: UpdateContactPayload;
  updateOrCreateAcquisition: UpdateOrCreateAcquisitionPayload;
  updateProperty: UpdatePropertyPayload;
  updatePropertyAssets: UpdatePropertyAssetsPayload;
  updatePropertyContacts: UpdatePropertyContactsPayload;
};

export type MutationCreateAssetsArgs = {
  data: AssetsInput;
};

export type MutationCreateContactArgs = {
  data: ContactInput;
};

export type MutationCreatePropertyArgs = {
  data: PropertyInput;
};

export type MutationDeleteAssetArgs = {
  data: DeleteAssetInput;
};

export type MutationDeleteContactArgs = {
  data: DeleteContactInput;
};

export type MutationDeletePropertyArgs = {
  data: DeletePropertyInput;
};

export type MutationUpdateAssetArgs = {
  data: AssetPartial;
};

export type MutationUpdateContactArgs = {
  data: ContactPartial;
};

export type MutationUpdateOrCreateAcquisitionArgs = {
  data: AcquisitionPartial;
};

export type MutationUpdatePropertyArgs = {
  data: PropertyPartial;
};

export type MutationUpdatePropertyAssetsArgs = {
  data: PropertyAssetPartial;
};

export type MutationUpdatePropertyContactsArgs = {
  contacts: Array<PropertyContactInput>;
  propertyId: Scalars["ID"]["input"];
};

export type OperationInfo = {
  __typename?: "OperationInfo";
  /** List of messages returned by the operation. */
  messages: Array<OperationMessage>;
};

export type OperationMessage = {
  __typename?: "OperationMessage";
  /** The error code, or `null` if no error code was set. */
  code?: Maybe<Scalars["String"]["output"]>;
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars["String"]["output"]>;
  /** The kind of this message. */
  kind: OperationMessageKind;
  /** The error message. */
  message: Scalars["String"]["output"];
};

export enum OperationMessageKind {
  Error = "ERROR",
  Info = "INFO",
  Permission = "PERMISSION",
  Validation = "VALIDATION",
  Warning = "WARNING",
}

export enum Ordering {
  Asc = "ASC",
  Desc = "DESC",
}

export type PaginatedAssets = {
  __typename?: "PaginatedAssets";
  data: Array<RawAsset>;
  meta: PaginationMeta;
};

export type PaginatedContacts = {
  __typename?: "PaginatedContacts";
  data: Array<Contact>;
  meta: PaginationMeta;
};

export type PaginatedProperties = {
  __typename?: "PaginatedProperties";
  data: Array<Property>;
  meta: PaginationMeta;
};

export type PaginationInput = {
  page?: Scalars["Int"]["input"];
  pageSize?: Scalars["Int"]["input"];
};

export type PaginationMeta = {
  __typename?: "PaginationMeta";
  hasNext: Scalars["Boolean"]["output"];
  hasPrevious: Scalars["Boolean"]["output"];
  pageCount: Scalars["Int"]["output"];
  pageCurrent: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
  totalCount: Scalars["Int"]["output"];
};

export enum ParkingType {
  BikeParking = "BIKE_PARKING",
  ParkingLots = "PARKING_LOTS",
}

/** Permission definition for schema directives. */
export type PermDefinition = {
  /** The app to which we are requiring permission. If this is empty that means that we are checking the permission directly. */
  app?: InputMaybe<Scalars["String"]["input"]>;
  /** The permission itself. If this is empty that means that we are checking for any permission for the given app. */
  permission?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Phase {
  BuildingProject = "BUILDING_PROJECT",
  Competition = "COMPETITION",
  Construction = "CONSTRUCTION",
  Management = "MANAGEMENT",
  PreProject = "PRE_PROJECT",
  ReadyToMoveInto = "READY_TO_MOVE_INTO",
  SpecialPurposePlanning = "SPECIAL_PURPOSE_PLANNING",
  Study = "STUDY",
}

export enum Portfolio {
  CommercialObject = "COMMERCIAL_OBJECT",
  InvestmentObject = "INVESTMENT_OBJECT",
  Stations = "STATIONS",
}

/** Property(id, status, publish_on_website, start_of_construction, move_into, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, import_key, project_id, phase, portfolio, region, main_language, economic_unit, land_area, total_area, area_zone_de, area_zone_fr, area_zone_it, area_zone_en, equity, investment_volume, street, street_number, zip_code, city, url_freebrand_website, url_brand_video, updated_at, updated_by, geom_area, geom_point, map_zoom, factsheet_left_upper, factsheet_right_upper, factsheet_lower, report_timestamp) */
export type Property = {
  __typename?: "Property";
  acquisition?: Maybe<Acquisition>;
  acquisitionFactsheetUris: FactsheetUris;
  appointments: Array<PropertyAppointment>;
  areaZoneDe: Scalars["String"]["output"];
  areaZoneEn: Scalars["String"]["output"];
  areaZoneFr: Scalars["String"]["output"];
  areaZoneIt: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  claimDe: Scalars["String"]["output"];
  claimEn: Scalars["String"]["output"];
  claimFr: Scalars["String"]["output"];
  claimIt: Scalars["String"]["output"];
  contacts: Array<PropertyContact>;
  economicUnit: Scalars["String"]["output"];
  equity?: Maybe<Scalars["Int"]["output"]>;
  factsheetImages: FactsheetPositions;
  files?: Maybe<Array<PropertyFile>>;
  gallery?: Maybe<Array<GalleryPicture>>;
  geomArea?: Maybe<Scalars["MultiPolygon"]["output"]>;
  geomPoint?: Maybe<Scalars["Point"]["output"]>;
  id: Scalars["ID"]["output"];
  importKey?: Maybe<Scalars["String"]["output"]>;
  investmentVolume?: Maybe<Scalars["Int"]["output"]>;
  keyVisuals?: Maybe<Array<KeyVisualAsset>>;
  landArea?: Maybe<Scalars["Int"]["output"]>;
  latestUpdate: LatestUpdate;
  leadDe: Scalars["String"]["output"];
  leadEn: Scalars["String"]["output"];
  leadFr: Scalars["String"]["output"];
  leadIt: Scalars["String"]["output"];
  links: Array<PropertyLink>;
  mainLanguage: MainLanguage;
  mainUsefulAreas: Array<MainUsefulArea>;
  mainUsefulAreasTotal?: Maybe<Scalars["Int"]["output"]>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  mapZoom: Scalars["Int"]["output"];
  moveInto?: Maybe<Scalars["Int"]["output"]>;
  parkings: Array<PropertyParking>;
  phase: Phase;
  portfolio?: Maybe<Portfolio>;
  projectFactsheetUris: FactsheetUris;
  projectId?: Maybe<Scalars["String"]["output"]>;
  projectNumbers: Array<PropertyProjectNumber>;
  publishOnWebsite: Scalars["Boolean"]["output"];
  region: Region;
  reportTimestamp: Scalars["DateTime"]["output"];
  startOfConstruction?: Maybe<Scalars["Int"]["output"]>;
  status: Status;
  street: Scalars["String"]["output"];
  streetNumber: Scalars["String"]["output"];
  teasers?: Maybe<Array<TeaserAsset>>;
  titleDe: Scalars["String"]["output"];
  titleEn: Scalars["String"]["output"];
  titleFr: Scalars["String"]["output"];
  titleIt: Scalars["String"]["output"];
  totalArea?: Maybe<Scalars["Int"]["output"]>;
  urlBrandVideo: Scalars["String"]["output"];
  urlFreebrandWebsite: Scalars["String"]["output"];
  zipCode: Scalars["String"]["output"];
};

/** Appointment(id, name_de, name_fr, name_it, name_en, year, index, property, updated_at, updated_by) */
export type PropertyAppointment = {
  __typename?: "PropertyAppointment";
  id: Scalars["ID"]["output"];
  nameDe: Scalars["String"]["output"];
  nameEn: Scalars["String"]["output"];
  nameFr: Scalars["String"]["output"];
  nameIt: Scalars["String"]["output"];
  year?: Maybe<Scalars["Int"]["output"]>;
};

/** Appointment(id, name_de, name_fr, name_it, name_en, year, index, property, updated_at, updated_by) */
export type PropertyAppointmentInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  nameDe?: InputMaybe<Scalars["String"]["input"]>;
  nameEn?: InputMaybe<Scalars["String"]["input"]>;
  nameFr?: InputMaybe<Scalars["String"]["input"]>;
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Appointment(id, name_de, name_fr, name_it, name_en, year, index, property, updated_at, updated_by) */
export type PropertyAppointmentPartial = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  nameDe?: InputMaybe<Scalars["String"]["input"]>;
  nameEn?: InputMaybe<Scalars["String"]["input"]>;
  nameFr?: InputMaybe<Scalars["String"]["input"]>;
  nameIt?: InputMaybe<Scalars["String"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PropertyAsset = {
  __typename?: "PropertyAsset";
  acquisitionFactsheetImages?: Maybe<FactsheetPositions>;
  files?: Maybe<Array<PropertyFile>>;
  gallery?: Maybe<Array<GalleryPicture>>;
  keyVisuals?: Maybe<Array<KeyVisualAsset>>;
  projectFactsheetImages?: Maybe<FactsheetPositions>;
  propertyId: Scalars["ID"]["output"];
  teasers?: Maybe<Array<TeaserAsset>>;
};

export type PropertyAssetPartial = {
  acquisitionFactsheetImages?: InputMaybe<FactsheetPositionsPartial>;
  files?: InputMaybe<Array<PropertyFilePartial>>;
  gallery?: InputMaybe<Array<GalleryPictureInput>>;
  keyVisuals?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  projectFactsheetImages?: InputMaybe<FactsheetPositionsPartial>;
  propertyId: Scalars["ID"]["input"];
  teasers?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** PropertyContact(id, contact, property, contact_type, updated_at, updated_by) */
export type PropertyContact = {
  __typename?: "PropertyContact";
  contact: Contact;
  contactType: ContactType;
  id: Scalars["ID"]["output"];
};

export type PropertyContactInput = {
  contactId: Scalars["ID"]["input"];
  contactType: Scalars["String"]["input"];
};

/** PropertyFile(id, asset, property, display_name_de, display_name_fr, display_name_it, display_name_en, index) */
export type PropertyFile = {
  __typename?: "PropertyFile";
  asset: RawAsset;
  displayNameDe: Scalars["String"]["output"];
  displayNameEn: Scalars["String"]["output"];
  displayNameFr: Scalars["String"]["output"];
  displayNameIt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

/** PropertyFile(id, asset, property, display_name_de, display_name_fr, display_name_it, display_name_en, index) */
export type PropertyFilePartial = {
  assetId: Scalars["ID"]["input"];
  displayNameDe?: InputMaybe<Scalars["String"]["input"]>;
  displayNameEn?: InputMaybe<Scalars["String"]["input"]>;
  displayNameFr?: InputMaybe<Scalars["String"]["input"]>;
  displayNameIt?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Property(id, status, publish_on_website, start_of_construction, move_into, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, import_key, project_id, phase, portfolio, region, main_language, economic_unit, land_area, total_area, area_zone_de, area_zone_fr, area_zone_it, area_zone_en, equity, investment_volume, street, street_number, zip_code, city, url_freebrand_website, url_brand_video, updated_at, updated_by, geom_area, geom_point, map_zoom, factsheet_left_upper, factsheet_right_upper, factsheet_lower, report_timestamp) */
export type PropertyFilter = {
  AND?: InputMaybe<PropertyFilter>;
  NOT?: InputMaybe<PropertyFilter>;
  OR?: InputMaybe<PropertyFilter>;
  contactId?: InputMaybe<Scalars["ID"]["input"]>;
  phase?: InputMaybe<Phase>;
  portfolio?: InputMaybe<Portfolio>;
  publishOnWebsite?: InputMaybe<Scalars["Boolean"]["input"]>;
  region?: InputMaybe<Region>;
  status?: InputMaybe<Status>;
  titleDe?: InputMaybe<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleFr?: InputMaybe<Scalars["String"]["input"]>;
  titleIt?: InputMaybe<Scalars["String"]["input"]>;
};

/** Property(id, status, publish_on_website, start_of_construction, move_into, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, import_key, project_id, phase, portfolio, region, main_language, economic_unit, land_area, total_area, area_zone_de, area_zone_fr, area_zone_it, area_zone_en, equity, investment_volume, street, street_number, zip_code, city, url_freebrand_website, url_brand_video, updated_at, updated_by, geom_area, geom_point, map_zoom, factsheet_left_upper, factsheet_right_upper, factsheet_lower, report_timestamp) */
export type PropertyInput = {
  appointments?: InputMaybe<Array<PropertyAppointmentInput>>;
  areaZoneDe?: InputMaybe<Scalars["String"]["input"]>;
  areaZoneEn?: InputMaybe<Scalars["String"]["input"]>;
  areaZoneFr?: InputMaybe<Scalars["String"]["input"]>;
  areaZoneIt?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  claimDe?: InputMaybe<Scalars["String"]["input"]>;
  claimEn?: InputMaybe<Scalars["String"]["input"]>;
  claimFr?: InputMaybe<Scalars["String"]["input"]>;
  claimIt?: InputMaybe<Scalars["String"]["input"]>;
  economicUnit?: InputMaybe<Scalars["String"]["input"]>;
  equity?: InputMaybe<Scalars["Int"]["input"]>;
  geomArea?: InputMaybe<Scalars["MultiPolygon"]["input"]>;
  geomPoint?: InputMaybe<Scalars["Point"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  importKey?: InputMaybe<Scalars["String"]["input"]>;
  investmentVolume?: InputMaybe<Scalars["Int"]["input"]>;
  landArea?: InputMaybe<Scalars["Int"]["input"]>;
  leadDe?: InputMaybe<Scalars["String"]["input"]>;
  leadEn?: InputMaybe<Scalars["String"]["input"]>;
  leadFr?: InputMaybe<Scalars["String"]["input"]>;
  leadIt?: InputMaybe<Scalars["String"]["input"]>;
  links?: InputMaybe<Array<PropertyLinkInput>>;
  mainLanguage?: InputMaybe<MainLanguage>;
  mainUsefulAreas?: InputMaybe<Array<MainUsefulAreaInput>>;
  mapZoom?: InputMaybe<Scalars["Int"]["input"]>;
  moveInto?: InputMaybe<Scalars["Int"]["input"]>;
  parkings?: InputMaybe<Array<PropertyParkingInput>>;
  phase?: InputMaybe<Phase>;
  portfolio?: InputMaybe<Portfolio>;
  projectId?: InputMaybe<Scalars["String"]["input"]>;
  projectNumbers?: InputMaybe<Array<PropertyProjectNumberInput>>;
  publishOnWebsite?: InputMaybe<Scalars["Boolean"]["input"]>;
  region?: InputMaybe<Region>;
  reportTimestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  startOfConstruction?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Status>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  streetNumber?: InputMaybe<Scalars["String"]["input"]>;
  titleDe?: InputMaybe<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleFr?: InputMaybe<Scalars["String"]["input"]>;
  titleIt?: InputMaybe<Scalars["String"]["input"]>;
  totalArea?: InputMaybe<Scalars["Int"]["input"]>;
  urlBrandVideo?: InputMaybe<Scalars["String"]["input"]>;
  urlFreebrandWebsite?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** Link(id, link_type, title_de, title_fr, title_it, title_en, url_de, url_fr, url_it, url_en, property, updated_at, updated_by) */
export type PropertyLink = {
  __typename?: "PropertyLink";
  id: Scalars["ID"]["output"];
  linkType?: Maybe<LinkType>;
  titleDe: Scalars["String"]["output"];
  titleEn: Scalars["String"]["output"];
  titleFr: Scalars["String"]["output"];
  titleIt: Scalars["String"]["output"];
  urlDe: Scalars["String"]["output"];
  urlEn: Scalars["String"]["output"];
  urlFr: Scalars["String"]["output"];
  urlIt: Scalars["String"]["output"];
};

/** Link(id, link_type, title_de, title_fr, title_it, title_en, url_de, url_fr, url_it, url_en, property, updated_at, updated_by) */
export type PropertyLinkInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  linkType?: InputMaybe<LinkType>;
  titleDe?: InputMaybe<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleFr?: InputMaybe<Scalars["String"]["input"]>;
  titleIt?: InputMaybe<Scalars["String"]["input"]>;
  urlDe?: InputMaybe<Scalars["String"]["input"]>;
  urlEn?: InputMaybe<Scalars["String"]["input"]>;
  urlFr?: InputMaybe<Scalars["String"]["input"]>;
  urlIt?: InputMaybe<Scalars["String"]["input"]>;
};

/** Link(id, link_type, title_de, title_fr, title_it, title_en, url_de, url_fr, url_it, url_en, property, updated_at, updated_by) */
export type PropertyLinkPartial = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  linkType?: InputMaybe<LinkType>;
  titleDe?: InputMaybe<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleFr?: InputMaybe<Scalars["String"]["input"]>;
  titleIt?: InputMaybe<Scalars["String"]["input"]>;
  urlDe?: InputMaybe<Scalars["String"]["input"]>;
  urlEn?: InputMaybe<Scalars["String"]["input"]>;
  urlFr?: InputMaybe<Scalars["String"]["input"]>;
  urlIt?: InputMaybe<Scalars["String"]["input"]>;
};

export type PropertyOrder = {
  titleDe?: InputMaybe<Ordering>;
  titleEn?: InputMaybe<Ordering>;
  titleFr?: InputMaybe<Ordering>;
  titleIt?: InputMaybe<Ordering>;
};

/** Parking(id, type, count, property, updated_at, updated_by) */
export type PropertyParking = {
  __typename?: "PropertyParking";
  count?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  type: ParkingType;
};

/** Parking(id, type, count, property, updated_at, updated_by) */
export type PropertyParkingInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  type: ParkingType;
};

/** Parking(id, type, count, property, updated_at, updated_by) */
export type PropertyParkingPartial = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  type: ParkingType;
};

/** Property(id, status, publish_on_website, start_of_construction, move_into, title_de, title_fr, title_it, title_en, claim_de, claim_fr, claim_it, claim_en, lead_de, lead_fr, lead_it, lead_en, import_key, project_id, phase, portfolio, region, main_language, economic_unit, land_area, total_area, area_zone_de, area_zone_fr, area_zone_it, area_zone_en, equity, investment_volume, street, street_number, zip_code, city, url_freebrand_website, url_brand_video, updated_at, updated_by, geom_area, geom_point, map_zoom, factsheet_left_upper, factsheet_right_upper, factsheet_lower, report_timestamp) */
export type PropertyPartial = {
  appointments?: InputMaybe<Array<PropertyAppointmentPartial>>;
  areaZoneDe?: InputMaybe<Scalars["String"]["input"]>;
  areaZoneEn?: InputMaybe<Scalars["String"]["input"]>;
  areaZoneFr?: InputMaybe<Scalars["String"]["input"]>;
  areaZoneIt?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  claimDe?: InputMaybe<Scalars["String"]["input"]>;
  claimEn?: InputMaybe<Scalars["String"]["input"]>;
  claimFr?: InputMaybe<Scalars["String"]["input"]>;
  claimIt?: InputMaybe<Scalars["String"]["input"]>;
  economicUnit?: InputMaybe<Scalars["String"]["input"]>;
  equity?: InputMaybe<Scalars["Int"]["input"]>;
  geomArea?: InputMaybe<Scalars["MultiPolygon"]["input"]>;
  geomPoint?: InputMaybe<Scalars["Point"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  importKey?: InputMaybe<Scalars["String"]["input"]>;
  investmentVolume?: InputMaybe<Scalars["Int"]["input"]>;
  landArea?: InputMaybe<Scalars["Int"]["input"]>;
  leadDe?: InputMaybe<Scalars["String"]["input"]>;
  leadEn?: InputMaybe<Scalars["String"]["input"]>;
  leadFr?: InputMaybe<Scalars["String"]["input"]>;
  leadIt?: InputMaybe<Scalars["String"]["input"]>;
  links?: InputMaybe<Array<PropertyLinkPartial>>;
  mainLanguage?: InputMaybe<MainLanguage>;
  mainUsefulAreas?: InputMaybe<Array<MainUsefulAreaPartial>>;
  mapZoom?: InputMaybe<Scalars["Int"]["input"]>;
  moveInto?: InputMaybe<Scalars["Int"]["input"]>;
  parkings?: InputMaybe<Array<PropertyParkingPartial>>;
  phase?: InputMaybe<Phase>;
  portfolio?: InputMaybe<Portfolio>;
  projectId?: InputMaybe<Scalars["String"]["input"]>;
  projectNumbers?: InputMaybe<Array<PropertyProjectNumberPartial>>;
  publishOnWebsite?: InputMaybe<Scalars["Boolean"]["input"]>;
  region?: InputMaybe<Region>;
  reportTimestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
  startOfConstruction?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Status>;
  street?: InputMaybe<Scalars["String"]["input"]>;
  streetNumber?: InputMaybe<Scalars["String"]["input"]>;
  titleDe?: InputMaybe<Scalars["String"]["input"]>;
  titleEn?: InputMaybe<Scalars["String"]["input"]>;
  titleFr?: InputMaybe<Scalars["String"]["input"]>;
  titleIt?: InputMaybe<Scalars["String"]["input"]>;
  totalArea?: InputMaybe<Scalars["Int"]["input"]>;
  urlBrandVideo?: InputMaybe<Scalars["String"]["input"]>;
  urlFreebrandWebsite?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
};

/** ProjectNumber(id, building_de, building_fr, building_it, building_en, number, property, updated_at, updated_by) */
export type PropertyProjectNumber = {
  __typename?: "PropertyProjectNumber";
  buildingDe: Scalars["String"]["output"];
  buildingEn: Scalars["String"]["output"];
  buildingFr: Scalars["String"]["output"];
  buildingIt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  number: Scalars["String"]["output"];
};

/** ProjectNumber(id, building_de, building_fr, building_it, building_en, number, property, updated_at, updated_by) */
export type PropertyProjectNumberInput = {
  buildingDe?: InputMaybe<Scalars["String"]["input"]>;
  buildingEn?: InputMaybe<Scalars["String"]["input"]>;
  buildingFr?: InputMaybe<Scalars["String"]["input"]>;
  buildingIt?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
};

/** ProjectNumber(id, building_de, building_fr, building_it, building_en, number, property, updated_at, updated_by) */
export type PropertyProjectNumberPartial = {
  buildingDe?: InputMaybe<Scalars["String"]["input"]>;
  buildingEn?: InputMaybe<Scalars["String"]["input"]>;
  buildingFr?: InputMaybe<Scalars["String"]["input"]>;
  buildingIt?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  acquisition: Acquisition;
  asset: RawAsset;
  assetTag: AssetTag;
  assetTags: Array<AssetTag>;
  assets: PaginatedAssets;
  contact: Contact;
  contactTypes: Array<ContactType>;
  contacts: PaginatedContacts;
  currentUser: User;
  properties: PaginatedProperties;
  property: Property;
};

export type QueryAcquisitionArgs = {
  pk: Scalars["ID"]["input"];
};

export type QueryAssetArgs = {
  pk: Scalars["ID"]["input"];
};

export type QueryAssetTagArgs = {
  pk: Scalars["ID"]["input"];
};

export type QueryAssetsArgs = {
  filters?: InputMaybe<AssetFilter>;
  order?: InputMaybe<AssetOrder>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryContactArgs = {
  pk: Scalars["ID"]["input"];
};

export type QueryContactsArgs = {
  filters?: InputMaybe<ContactFilter>;
  order?: InputMaybe<ContactOrder>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryPropertiesArgs = {
  filters?: InputMaybe<PropertyFilter>;
  order?: InputMaybe<PropertyOrder>;
  pagination?: InputMaybe<PaginationInput>;
};

export type QueryPropertyArgs = {
  pk: Scalars["ID"]["input"];
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type RawAsset = Asset & {
  __typename?: "RawAsset";
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

export enum Region {
  Center = "CENTER",
  East = "EAST",
  West = "WEST",
}

export enum Status {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

/** Teaser(id, x, y, width, height, rotation, asset) */
export type Teaser = {
  __typename?: "Teaser";
  height: Scalars["Int"]["output"];
  rotation: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  width: Scalars["Int"]["output"];
  x: Scalars["Int"]["output"];
  y: Scalars["Int"]["output"];
};

/** Asset(id, file_type, file, name, display_category, uuid, alt_text_de, alt_text_fr, alt_text_it, alt_text_en, id_in_import) */
export type TeaserAsset = Asset & {
  __typename?: "TeaserAsset";
  altTextDe: Scalars["String"]["output"];
  altTextEn: Scalars["String"]["output"];
  altTextFr: Scalars["String"]["output"];
  altTextIt: Scalars["String"]["output"];
  assetTags: Array<AssetTag>;
  displayCategory?: Maybe<DisplayCategory>;
  factsheetLarge?: Maybe<FactsheetLarge>;
  factsheetLeft?: Maybe<FactsheetLeft>;
  file: DjangoFileType;
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  imageHeight?: Maybe<Scalars["Int"]["output"]>;
  imageWidth?: Maybe<Scalars["Int"]["output"]>;
  keyVisual?: Maybe<KeyVisual>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  teaser?: Maybe<Teaser>;
  url: Scalars["String"]["output"];
  uuid?: Maybe<Scalars["String"]["output"]>;
};

/** Teaser(id, x, y, width, height, rotation, asset) */
export type TeaserInput = {
  height: Scalars["Int"]["input"];
  rotation: Scalars["Int"]["input"];
  width: Scalars["Int"]["input"];
  x: Scalars["Int"]["input"];
  y: Scalars["Int"]["input"];
};

export type UpdateAssetPayload = OperationInfo | RawAsset;

export type UpdateContactPayload = Contact | OperationInfo;

export type UpdateOrCreateAcquisitionPayload = Acquisition | OperationInfo;

export type UpdatePropertyAssetsPayload = OperationInfo | PropertyAsset;

export type UpdatePropertyContactsPayload = OperationInfo | Property;

export type UpdatePropertyPayload = OperationInfo | Property;

/** User(id, password, last_login, is_superuser, is_staff, is_active, date_joined, email, username, first_name, last_name, company, extra_data) */
export type User = {
  __typename?: "User";
  company: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: Array<Group>;
  lastName: Scalars["String"]["output"];
  permissions: Array<Scalars["String"]["output"]>;
  /** U-/E-Number in lowercase, e.g. "e123456" (without quotation marks) */
  username: Scalars["String"]["output"];
};

type AssetForm_FactsheetLargeAsset_Fragment = {
  __typename?: "FactsheetLargeAsset";
  id: string;
  name: string;
  altTextDe: string;
  altTextFr: string;
  altTextIt: string;
  altTextEn: string;
  displayCategory?: DisplayCategory | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
  factsheetLarge?: {
    __typename?: "FactsheetLarge";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  keyVisual?: {
    __typename?: "KeyVisual";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  teaser?: {
    __typename?: "Teaser";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetForm_FactsheetLeftAsset_Fragment = {
  __typename?: "FactsheetLeftAsset";
  id: string;
  name: string;
  altTextDe: string;
  altTextFr: string;
  altTextIt: string;
  altTextEn: string;
  displayCategory?: DisplayCategory | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
  factsheetLarge?: {
    __typename?: "FactsheetLarge";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  keyVisual?: {
    __typename?: "KeyVisual";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  teaser?: {
    __typename?: "Teaser";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetForm_GalleryAsset_Fragment = {
  __typename?: "GalleryAsset";
  id: string;
  name: string;
  altTextDe: string;
  altTextFr: string;
  altTextIt: string;
  altTextEn: string;
  displayCategory?: DisplayCategory | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
  factsheetLarge?: {
    __typename?: "FactsheetLarge";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  keyVisual?: {
    __typename?: "KeyVisual";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  teaser?: {
    __typename?: "Teaser";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetForm_KeyVisualAsset_Fragment = {
  __typename?: "KeyVisualAsset";
  id: string;
  name: string;
  altTextDe: string;
  altTextFr: string;
  altTextIt: string;
  altTextEn: string;
  displayCategory?: DisplayCategory | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
  factsheetLarge?: {
    __typename?: "FactsheetLarge";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  keyVisual?: {
    __typename?: "KeyVisual";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  teaser?: {
    __typename?: "Teaser";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetForm_RawAsset_Fragment = {
  __typename?: "RawAsset";
  id: string;
  name: string;
  altTextDe: string;
  altTextFr: string;
  altTextIt: string;
  altTextEn: string;
  displayCategory?: DisplayCategory | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
  factsheetLarge?: {
    __typename?: "FactsheetLarge";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  keyVisual?: {
    __typename?: "KeyVisual";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  teaser?: {
    __typename?: "Teaser";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetForm_TeaserAsset_Fragment = {
  __typename?: "TeaserAsset";
  id: string;
  name: string;
  altTextDe: string;
  altTextFr: string;
  altTextIt: string;
  altTextEn: string;
  displayCategory?: DisplayCategory | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
  factsheetLarge?: {
    __typename?: "FactsheetLarge";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  keyVisual?: {
    __typename?: "KeyVisual";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
  teaser?: {
    __typename?: "Teaser";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

export type AssetFormFragment =
  | AssetForm_FactsheetLargeAsset_Fragment
  | AssetForm_FactsheetLeftAsset_Fragment
  | AssetForm_GalleryAsset_Fragment
  | AssetForm_KeyVisualAsset_Fragment
  | AssetForm_RawAsset_Fragment
  | AssetForm_TeaserAsset_Fragment;

export type UpdateAssetMutationVariables = Exact<{
  data: AssetPartial;
}>;

export type UpdateAssetMutation = {
  __typename?: "Mutation";
  updateAsset:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | {
        __typename?: "RawAsset";
        id: string;
        name: string;
        altTextDe: string;
        altTextFr: string;
        altTextIt: string;
        altTextEn: string;
        displayCategory?: DisplayCategory | null;
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
        factsheetLarge?: {
          __typename?: "FactsheetLarge";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
        keyVisual?: {
          __typename?: "KeyVisual";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
        teaser?: {
          __typename?: "Teaser";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      };
};

type AssetInfo_FactsheetLargeAsset_Fragment = {
  __typename?: "FactsheetLargeAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
};

type AssetInfo_FactsheetLeftAsset_Fragment = {
  __typename?: "FactsheetLeftAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
};

type AssetInfo_GalleryAsset_Fragment = {
  __typename?: "GalleryAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
};

type AssetInfo_KeyVisualAsset_Fragment = {
  __typename?: "KeyVisualAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
};

type AssetInfo_RawAsset_Fragment = {
  __typename?: "RawAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
};

type AssetInfo_TeaserAsset_Fragment = {
  __typename?: "TeaserAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
};

export type AssetInfoFragment =
  | AssetInfo_FactsheetLargeAsset_Fragment
  | AssetInfo_FactsheetLeftAsset_Fragment
  | AssetInfo_GalleryAsset_Fragment
  | AssetInfo_KeyVisualAsset_Fragment
  | AssetInfo_RawAsset_Fragment
  | AssetInfo_TeaserAsset_Fragment;

export type AssetTagsSelectQueryVariables = Exact<{ [key: string]: never }>;

export type AssetTagsSelectQuery = {
  __typename?: "Query";
  assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
};

type AssetThumbnail_FactsheetLargeAsset_Fragment = {
  __typename?: "FactsheetLargeAsset";
  id: string;
  name: string;
  uuid?: string | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetThumbnail_FactsheetLeftAsset_Fragment = {
  __typename?: "FactsheetLeftAsset";
  id: string;
  name: string;
  uuid?: string | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetThumbnail_GalleryAsset_Fragment = {
  __typename?: "GalleryAsset";
  id: string;
  name: string;
  uuid?: string | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetThumbnail_KeyVisualAsset_Fragment = {
  __typename?: "KeyVisualAsset";
  id: string;
  name: string;
  uuid?: string | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetThumbnail_RawAsset_Fragment = {
  __typename?: "RawAsset";
  id: string;
  name: string;
  uuid?: string | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetThumbnail_TeaserAsset_Fragment = {
  __typename?: "TeaserAsset";
  id: string;
  name: string;
  uuid?: string | null;
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  file: { __typename?: "DjangoFileType"; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

export type AssetThumbnailFragment =
  | AssetThumbnail_FactsheetLargeAsset_Fragment
  | AssetThumbnail_FactsheetLeftAsset_Fragment
  | AssetThumbnail_GalleryAsset_Fragment
  | AssetThumbnail_KeyVisualAsset_Fragment
  | AssetThumbnail_RawAsset_Fragment
  | AssetThumbnail_TeaserAsset_Fragment;

type AssetsField_FactsheetLargeAsset_Fragment = {
  __typename?: "FactsheetLargeAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  id: string;
  name: string;
  uuid?: string | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetsField_FactsheetLeftAsset_Fragment = {
  __typename?: "FactsheetLeftAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  id: string;
  name: string;
  uuid?: string | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetsField_GalleryAsset_Fragment = {
  __typename?: "GalleryAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  id: string;
  name: string;
  uuid?: string | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetsField_KeyVisualAsset_Fragment = {
  __typename?: "KeyVisualAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  id: string;
  name: string;
  uuid?: string | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetsField_RawAsset_Fragment = {
  __typename?: "RawAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  id: string;
  name: string;
  uuid?: string | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

type AssetsField_TeaserAsset_Fragment = {
  __typename?: "TeaserAsset";
  fileType: string;
  imageHeight?: number | null;
  imageWidth?: number | null;
  id: string;
  name: string;
  uuid?: string | null;
  file: { __typename?: "DjangoFileType"; size: number; url: string };
  factsheetLeft?: {
    __typename?: "FactsheetLeft";
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  } | null;
};

export type AssetsFieldFragment =
  | AssetsField_FactsheetLargeAsset_Fragment
  | AssetsField_FactsheetLeftAsset_Fragment
  | AssetsField_GalleryAsset_Fragment
  | AssetsField_KeyVisualAsset_Fragment
  | AssetsField_RawAsset_Fragment
  | AssetsField_TeaserAsset_Fragment;

export type AssetsFieldQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type AssetsFieldQuery = {
  __typename?: "Query";
  asset: {
    __typename?: "RawAsset";
    id: string;
    name: string;
    altTextDe: string;
    altTextFr: string;
    altTextIt: string;
    altTextEn: string;
    displayCategory?: DisplayCategory | null;
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
    factsheetLarge?: {
      __typename?: "FactsheetLarge";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
    keyVisual?: {
      __typename?: "KeyVisual";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
    teaser?: {
      __typename?: "Teaser";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  };
};

export type ContactFormFragment = {
  __typename?: "Contact";
  id: string;
  unit: ContactUnit;
  status: Status;
  formOfAddress: FormOfAddress;
  name: string;
  lastName: string;
  company: string;
  addressSupplement: string;
  email: string;
  website: string;
  phone: string;
  mobile: string;
  street: string;
  streetNumber: string;
  zipCode?: string | null;
  city: string;
  canton: string;
  country: string;
};

export type LayoutQueryVariables = Exact<{ [key: string]: never }>;

export type LayoutQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    firstName: string;
    lastName: string;
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
};

export type CreateAssetsMutationVariables = Exact<{
  data: AssetsInput;
}>;

export type CreateAssetsMutation = {
  __typename?: "Mutation";
  createAssets:
    | {
        __typename?: "AssetList";
        assets: Array<{ __typename?: "RawAsset"; id: string }>;
      }
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      };
};

export type AssetGridQueryVariables = Exact<{
  filters?: InputMaybe<AssetFilter>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type AssetGridQuery = {
  __typename?: "Query";
  assets: {
    __typename?: "PaginatedAssets";
    data: Array<{
      __typename?: "RawAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    }>;
    meta: { __typename?: "PaginationMeta"; totalCount: number };
  };
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
};

export type PropertyFormFragment = {
  __typename?: "Property";
  id: string;
  areaZoneDe: string;
  areaZoneFr: string;
  areaZoneIt: string;
  areaZoneEn: string;
  city: string;
  claimDe: string;
  claimFr: string;
  claimIt: string;
  claimEn: string;
  economicUnit: string;
  equity?: number | null;
  investmentVolume?: number | null;
  landArea?: number | null;
  leadDe: string;
  leadFr: string;
  leadIt: string;
  leadEn: string;
  mainLanguage: MainLanguage;
  moveInto?: number | null;
  phase: Phase;
  portfolio?: Portfolio | null;
  publishOnWebsite: boolean;
  region: Region;
  startOfConstruction?: number | null;
  status: Status;
  street: string;
  streetNumber: string;
  titleDe: string;
  titleFr: string;
  titleIt: string;
  titleEn: string;
  totalArea?: number | null;
  urlFreebrandWebsite: string;
  urlBrandVideo: string;
  zipCode: string;
  appointments: Array<{
    __typename?: "PropertyAppointment";
    id: string;
    nameDe: string;
    nameFr: string;
    nameIt: string;
    nameEn: string;
    year?: number | null;
  }>;
  links: Array<{
    __typename?: "PropertyLink";
    id: string;
    linkType?: LinkType | null;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    titleEn: string;
    urlDe: string;
    urlFr: string;
    urlIt: string;
    urlEn: string;
  }>;
  mainUsefulAreas: Array<{
    __typename?: "MainUsefulArea";
    id: string;
    product?: MainUsefulAreaTypes | null;
    squareMeters?: number | null;
  }>;
  parkings: Array<{
    __typename?: "PropertyParking";
    id: string;
    type: ParkingType;
    count?: number | null;
  }>;
  projectNumbers: Array<{
    __typename?: "PropertyProjectNumber";
    id: string;
    buildingDe: string;
    buildingFr: string;
    buildingIt: string;
    buildingEn: string;
    number: string;
  }>;
};

export type PropertyLayoutFragment = {
  __typename?: "Property";
  portfolio?: Portfolio | null;
  titleDe: string;
  titleFr: string;
  titleIt: string;
  latestUpdate: {
    __typename?: "LatestUpdate";
    at: any;
    by?: { __typename?: "User"; firstName: string; lastName: string } | null;
  };
};

export type UsedAtFragment = {
  __typename?: "Property";
  id: string;
  titleDe: string;
  titleFr: string;
  titleIt: string;
};

export type CurrentUserGroupsFragment = {
  __typename?: "User";
  groups: Array<{ __typename?: "Group"; name: string }>;
};

export type OperationInfoFragment = {
  __typename: "OperationInfo";
  messages: Array<{
    __typename?: "OperationMessage";
    code?: string | null;
    field?: string | null;
    kind: OperationMessageKind;
  }>;
};

export type DeleteContactMutationVariables = Exact<{
  data: DeleteContactInput;
}>;

export type DeleteContactMutation = {
  __typename?: "Mutation";
  deleteContact:
    | { __typename: "Contact" }
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      };
};

export type UpdateContactMutationVariables = Exact<{
  data: ContactPartial;
}>;

export type UpdateContactMutation = {
  __typename?: "Mutation";
  updateContact:
    | {
        __typename?: "Contact";
        hasProperty: boolean;
        id: string;
        unit: ContactUnit;
        status: Status;
        formOfAddress: FormOfAddress;
        name: string;
        lastName: string;
        company: string;
        addressSupplement: string;
        email: string;
        website: string;
        phone: string;
        mobile: string;
        street: string;
        streetNumber: string;
        zipCode?: string | null;
        city: string;
        canton: string;
        country: string;
      }
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      };
};

export type ContactPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ContactPageQuery = {
  __typename?: "Query";
  contact: {
    __typename?: "Contact";
    hasProperty: boolean;
    id: string;
    unit: ContactUnit;
    status: Status;
    formOfAddress: FormOfAddress;
    name: string;
    lastName: string;
    company: string;
    addressSupplement: string;
    email: string;
    website: string;
    phone: string;
    mobile: string;
    street: string;
    streetNumber: string;
    zipCode?: string | null;
    city: string;
    canton: string;
    country: string;
  };
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
};

export type ContactPagePropertiesQueryVariables = Exact<{
  filters?: InputMaybe<PropertyFilter>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type ContactPagePropertiesQuery = {
  __typename?: "Query";
  properties: {
    __typename?: "PaginatedProperties";
    data: Array<{
      __typename?: "Property";
      id: string;
      titleDe: string;
      titleFr: string;
      titleIt: string;
    }>;
    meta: { __typename?: "PaginationMeta"; totalCount: number };
  };
};

export type CreateContactMutationVariables = Exact<{
  data: ContactInput;
}>;

export type CreateContactMutation = {
  __typename?: "Mutation";
  createContact:
    | { __typename?: "Contact"; id: string }
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      };
};

export type ContactTableQueryVariables = Exact<{
  filters?: InputMaybe<ContactFilter>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type ContactTableQuery = {
  __typename?: "Query";
  contacts: {
    __typename?: "PaginatedContacts";
    data: Array<{
      __typename?: "Contact";
      id: string;
      name: string;
      lastName: string;
      unit: ContactUnit;
      company: string;
      hasProperty: boolean;
    }>;
    meta: { __typename?: "PaginationMeta"; totalCount: number };
  };
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
};

export type PropertyTableQueryVariables = Exact<{
  filters?: InputMaybe<PropertyFilter>;
  order?: InputMaybe<PropertyOrder>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type PropertyTableQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
  properties: {
    __typename?: "PaginatedProperties";
    data: Array<{
      __typename?: "Property";
      id: string;
      phase: Phase;
      portfolio?: Portfolio | null;
      region: Region;
      titleDe: string;
      titleFr: string;
      titleIt: string;
    }>;
    meta: { __typename?: "PaginationMeta"; totalCount: number };
  };
};

export type DeleteAssetMutationVariables = Exact<{
  data: DeleteAssetInput;
}>;

export type DeleteAssetMutation = {
  __typename?: "Mutation";
  deleteAsset:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | { __typename: "RawAsset" };
};

export type MediaPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type MediaPageQuery = {
  __typename?: "Query";
  asset: {
    __typename?: "RawAsset";
    id: string;
    name: string;
    altTextDe: string;
    altTextFr: string;
    altTextIt: string;
    altTextEn: string;
    displayCategory?: DisplayCategory | null;
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    uuid?: string | null;
    properties: Array<{
      __typename?: "Property";
      id: string;
      titleDe: string;
      titleFr: string;
      titleIt: string;
    }>;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    assetTags: Array<{ __typename?: "AssetTag"; name: string }>;
    factsheetLarge?: {
      __typename?: "FactsheetLarge";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
    keyVisual?: {
      __typename?: "KeyVisual";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
    teaser?: {
      __typename?: "Teaser";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  };
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
};

export type AcquisitionFormFragment = {
  __typename?: "Acquisition";
  id: string;
  titleDe: string;
  titleFr: string;
  titleIt: string;
  titleEn: string;
  claimDe: string;
  claimFr: string;
  claimIt: string;
  claimEn: string;
  leadDe: string;
  leadFr: string;
  leadIt: string;
  leadEn: string;
  floorAreas: Array<{
    __typename?: "FloorArea";
    id: string;
    nameDe: string;
    nameFr: string;
    nameIt: string;
    nameEn: string;
    squareMeters?: number | null;
  }>;
  availableAreas: Array<{
    __typename?: "AvailableArea";
    id: string;
    nameDe: string;
    nameFr: string;
    nameIt: string;
    nameEn: string;
    squareMeters?: number | null;
  }>;
  expansionRentableAreas: Array<{
    __typename?: "ExpansionRentableArea";
    id: string;
    nameDe: string;
    nameFr: string;
    nameIt: string;
    nameEn: string;
    expansionDe: string;
    expansionFr: string;
    expansionIt: string;
    expansionEn: string;
  }>;
};

export type UpdateAcquisitionMutationVariables = Exact<{
  data: AcquisitionPartial;
}>;

export type UpdateAcquisitionMutation = {
  __typename?: "Mutation";
  updateOrCreateAcquisition:
    | {
        __typename?: "Acquisition";
        id: string;
        titleDe: string;
        titleFr: string;
        titleIt: string;
        titleEn: string;
        claimDe: string;
        claimFr: string;
        claimIt: string;
        claimEn: string;
        leadDe: string;
        leadFr: string;
        leadIt: string;
        leadEn: string;
        property: {
          __typename?: "Property";
          portfolio?: Portfolio | null;
          titleDe: string;
          titleFr: string;
          titleIt: string;
          latestUpdate: {
            __typename?: "LatestUpdate";
            at: any;
            by?: {
              __typename?: "User";
              firstName: string;
              lastName: string;
            } | null;
          };
        };
        floorAreas: Array<{
          __typename?: "FloorArea";
          id: string;
          nameDe: string;
          nameFr: string;
          nameIt: string;
          nameEn: string;
          squareMeters?: number | null;
        }>;
        availableAreas: Array<{
          __typename?: "AvailableArea";
          id: string;
          nameDe: string;
          nameFr: string;
          nameIt: string;
          nameEn: string;
          squareMeters?: number | null;
        }>;
        expansionRentableAreas: Array<{
          __typename?: "ExpansionRentableArea";
          id: string;
          nameDe: string;
          nameFr: string;
          nameIt: string;
          nameEn: string;
          expansionDe: string;
          expansionFr: string;
          expansionIt: string;
          expansionEn: string;
        }>;
      }
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      };
};

export type PropertyAcquisitionPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PropertyAcquisitionPageQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
  property: {
    __typename?: "Property";
    portfolio?: Portfolio | null;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    acquisition?: {
      __typename?: "Acquisition";
      id: string;
      titleDe: string;
      titleFr: string;
      titleIt: string;
      titleEn: string;
      claimDe: string;
      claimFr: string;
      claimIt: string;
      claimEn: string;
      leadDe: string;
      leadFr: string;
      leadIt: string;
      leadEn: string;
      floorAreas: Array<{
        __typename?: "FloorArea";
        id: string;
        nameDe: string;
        nameFr: string;
        nameIt: string;
        nameEn: string;
        squareMeters?: number | null;
      }>;
      availableAreas: Array<{
        __typename?: "AvailableArea";
        id: string;
        nameDe: string;
        nameFr: string;
        nameIt: string;
        nameEn: string;
        squareMeters?: number | null;
      }>;
      expansionRentableAreas: Array<{
        __typename?: "ExpansionRentableArea";
        id: string;
        nameDe: string;
        nameFr: string;
        nameIt: string;
        nameEn: string;
        expansionDe: string;
        expansionFr: string;
        expansionIt: string;
        expansionEn: string;
      }>;
    } | null;
    latestUpdate: {
      __typename?: "LatestUpdate";
      at: any;
      by?: { __typename?: "User"; firstName: string; lastName: string } | null;
    };
  };
};

export type PropertyContactsFormFragment = {
  __typename?: "Property";
  contacts: Array<{
    __typename?: "PropertyContact";
    contactType: { __typename?: "ContactType"; name: ContactTypeName };
    contact: { __typename?: "Contact"; id: string };
  }>;
};

export type PropertyContactsPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PropertyContactsPageQuery = {
  __typename?: "Query";
  contacts: {
    __typename?: "PaginatedContacts";
    data: Array<{
      __typename?: "Contact";
      id: string;
      unit: ContactUnit;
      name: string;
      lastName: string;
      company: string;
    }>;
  };
  contactTypes: Array<{
    __typename?: "ContactType";
    name: ContactTypeName;
    sortIndex: number;
  }>;
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
  property: {
    __typename?: "Property";
    portfolio?: Portfolio | null;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    contacts: Array<{
      __typename?: "PropertyContact";
      contactType: { __typename?: "ContactType"; name: ContactTypeName };
      contact: { __typename?: "Contact"; id: string };
    }>;
    latestUpdate: {
      __typename?: "LatestUpdate";
      at: any;
      by?: { __typename?: "User"; firstName: string; lastName: string } | null;
    };
  };
};

export type UpdatePropertyContactsMutationVariables = Exact<{
  propertyId: Scalars["ID"]["input"];
  contacts: Array<PropertyContactInput> | PropertyContactInput;
}>;

export type UpdatePropertyContactsMutation = {
  __typename?: "Mutation";
  updatePropertyContacts:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | {
        __typename?: "Property";
        portfolio?: Portfolio | null;
        titleDe: string;
        titleFr: string;
        titleIt: string;
        contacts: Array<{
          __typename?: "PropertyContact";
          contactType: { __typename?: "ContactType"; name: ContactTypeName };
          contact: { __typename?: "Contact"; id: string };
        }>;
        latestUpdate: {
          __typename?: "LatestUpdate";
          at: any;
          by?: {
            __typename?: "User";
            firstName: string;
            lastName: string;
          } | null;
        };
      };
};

export type DeletePropertyMutationVariables = Exact<{
  data: DeletePropertyInput;
}>;

export type DeletePropertyMutation = {
  __typename?: "Mutation";
  deleteProperty:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | { __typename?: "Property"; id: string };
};

export type UpdatePropertyMutationVariables = Exact<{
  data: PropertyPartial;
}>;

export type UpdatePropertyMutation = {
  __typename?: "Mutation";
  updateProperty:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | {
        __typename?: "Property";
        id: string;
        areaZoneDe: string;
        areaZoneFr: string;
        areaZoneIt: string;
        areaZoneEn: string;
        city: string;
        claimDe: string;
        claimFr: string;
        claimIt: string;
        claimEn: string;
        economicUnit: string;
        equity?: number | null;
        investmentVolume?: number | null;
        landArea?: number | null;
        leadDe: string;
        leadFr: string;
        leadIt: string;
        leadEn: string;
        mainLanguage: MainLanguage;
        moveInto?: number | null;
        phase: Phase;
        portfolio?: Portfolio | null;
        publishOnWebsite: boolean;
        region: Region;
        startOfConstruction?: number | null;
        status: Status;
        street: string;
        streetNumber: string;
        titleDe: string;
        titleFr: string;
        titleIt: string;
        titleEn: string;
        totalArea?: number | null;
        urlFreebrandWebsite: string;
        urlBrandVideo: string;
        zipCode: string;
        appointments: Array<{
          __typename?: "PropertyAppointment";
          id: string;
          nameDe: string;
          nameFr: string;
          nameIt: string;
          nameEn: string;
          year?: number | null;
        }>;
        links: Array<{
          __typename?: "PropertyLink";
          id: string;
          linkType?: LinkType | null;
          titleDe: string;
          titleFr: string;
          titleIt: string;
          titleEn: string;
          urlDe: string;
          urlFr: string;
          urlIt: string;
          urlEn: string;
        }>;
        mainUsefulAreas: Array<{
          __typename?: "MainUsefulArea";
          id: string;
          product?: MainUsefulAreaTypes | null;
          squareMeters?: number | null;
        }>;
        parkings: Array<{
          __typename?: "PropertyParking";
          id: string;
          type: ParkingType;
          count?: number | null;
        }>;
        projectNumbers: Array<{
          __typename?: "PropertyProjectNumber";
          id: string;
          buildingDe: string;
          buildingFr: string;
          buildingIt: string;
          buildingEn: string;
          number: string;
        }>;
        latestUpdate: {
          __typename?: "LatestUpdate";
          at: any;
          by?: {
            __typename?: "User";
            firstName: string;
            lastName: string;
          } | null;
        };
      };
};

export type PropertyDataPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PropertyDataPageQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
  property: {
    __typename?: "Property";
    id: string;
    areaZoneDe: string;
    areaZoneFr: string;
    areaZoneIt: string;
    areaZoneEn: string;
    city: string;
    claimDe: string;
    claimFr: string;
    claimIt: string;
    claimEn: string;
    economicUnit: string;
    equity?: number | null;
    investmentVolume?: number | null;
    landArea?: number | null;
    leadDe: string;
    leadFr: string;
    leadIt: string;
    leadEn: string;
    mainLanguage: MainLanguage;
    moveInto?: number | null;
    phase: Phase;
    portfolio?: Portfolio | null;
    publishOnWebsite: boolean;
    region: Region;
    startOfConstruction?: number | null;
    status: Status;
    street: string;
    streetNumber: string;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    titleEn: string;
    totalArea?: number | null;
    urlFreebrandWebsite: string;
    urlBrandVideo: string;
    zipCode: string;
    appointments: Array<{
      __typename?: "PropertyAppointment";
      id: string;
      nameDe: string;
      nameFr: string;
      nameIt: string;
      nameEn: string;
      year?: number | null;
    }>;
    links: Array<{
      __typename?: "PropertyLink";
      id: string;
      linkType?: LinkType | null;
      titleDe: string;
      titleFr: string;
      titleIt: string;
      titleEn: string;
      urlDe: string;
      urlFr: string;
      urlIt: string;
      urlEn: string;
    }>;
    mainUsefulAreas: Array<{
      __typename?: "MainUsefulArea";
      id: string;
      product?: MainUsefulAreaTypes | null;
      squareMeters?: number | null;
    }>;
    parkings: Array<{
      __typename?: "PropertyParking";
      id: string;
      type: ParkingType;
      count?: number | null;
    }>;
    projectNumbers: Array<{
      __typename?: "PropertyProjectNumber";
      id: string;
      buildingDe: string;
      buildingFr: string;
      buildingIt: string;
      buildingEn: string;
      number: string;
    }>;
    latestUpdate: {
      __typename?: "LatestUpdate";
      at: any;
      by?: { __typename?: "User"; firstName: string; lastName: string } | null;
    };
  };
};

export type PropertyExportPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PropertyExportPageQuery = {
  __typename?: "Query";
  property: {
    __typename?: "Property";
    portfolio?: Portfolio | null;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    projectFactsheetUris: {
      __typename?: "FactsheetUris";
      de: string;
      fr: string;
      it: string;
    };
    acquisitionFactsheetUris: {
      __typename?: "FactsheetUris";
      de: string;
      fr: string;
      it: string;
    };
    latestUpdate: {
      __typename?: "LatestUpdate";
      at: any;
      by?: { __typename?: "User"; firstName: string; lastName: string } | null;
    };
  };
};

export type PropertyGeocodeFragment = {
  __typename?: "Property";
  city: string;
  mainLanguage: MainLanguage;
  street: string;
  streetNumber: string;
  zipCode: string;
};

export type PropertyMapPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PropertyMapPageQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
  property: {
    __typename?: "Property";
    geomArea?: any | null;
    geomPoint?: any | null;
    mapZoom: number;
    city: string;
    mainLanguage: MainLanguage;
    street: string;
    streetNumber: string;
    zipCode: string;
    portfolio?: Portfolio | null;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    latestUpdate: {
      __typename?: "LatestUpdate";
      at: any;
      by?: { __typename?: "User"; firstName: string; lastName: string } | null;
    };
  };
};

export type UpdatePropertyMapMutationVariables = Exact<{
  data: PropertyPartial;
}>;

export type UpdatePropertyMapMutation = {
  __typename?: "Mutation";
  updateProperty:
    | { __typename?: "OperationInfo" }
    | {
        __typename: "Property";
        geomArea?: any | null;
        geomPoint?: any | null;
        mapZoom: number;
        city: string;
        mainLanguage: MainLanguage;
        street: string;
        streetNumber: string;
        zipCode: string;
        portfolio?: Portfolio | null;
        titleDe: string;
        titleFr: string;
        titleIt: string;
        latestUpdate: {
          __typename?: "LatestUpdate";
          at: any;
          by?: {
            __typename?: "User";
            firstName: string;
            lastName: string;
          } | null;
        };
      };
};

export type FactsheetPositionsFragment = {
  __typename?: "FactsheetPositions";
  leftUpper?: {
    __typename?: "FactsheetLeftAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  } | null;
  rightUpper?: {
    __typename?: "FactsheetLargeAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  } | null;
  lower?: {
    __typename?: "FactsheetLargeAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  } | null;
};

export type PropertyMediaFragment = {
  __typename?: "Property";
  acquisition?: {
    __typename?: "Acquisition";
    factsheetImages: {
      __typename?: "FactsheetPositions";
      leftUpper?: {
        __typename?: "FactsheetLeftAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      } | null;
      rightUpper?: {
        __typename?: "FactsheetLargeAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      } | null;
      lower?: {
        __typename?: "FactsheetLargeAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      } | null;
    };
  } | null;
  factsheetImages: {
    __typename?: "FactsheetPositions";
    leftUpper?: {
      __typename?: "FactsheetLeftAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
    rightUpper?: {
      __typename?: "FactsheetLargeAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
    lower?: {
      __typename?: "FactsheetLargeAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
  };
  teasers?: Array<{
    __typename?: "TeaserAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  }> | null;
  keyVisuals?: Array<{
    __typename?: "KeyVisualAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  }> | null;
  gallery?: Array<{
    __typename?: "GalleryPicture";
    index: number;
    asset: {
      __typename?: "GalleryAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    };
  }> | null;
  files?: Array<{
    __typename?: "PropertyFile";
    displayNameDe: string;
    displayNameFr: string;
    displayNameIt: string;
    displayNameEn: string;
    asset: {
      __typename?: "RawAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    };
  }> | null;
};

export type PropertyAssetFragment = {
  __typename?: "PropertyAsset";
  acquisitionFactsheetImages?: {
    __typename?: "FactsheetPositions";
    leftUpper?: {
      __typename?: "FactsheetLeftAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
    rightUpper?: {
      __typename?: "FactsheetLargeAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
    lower?: {
      __typename?: "FactsheetLargeAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
  } | null;
  projectFactsheetImages?: {
    __typename?: "FactsheetPositions";
    leftUpper?: {
      __typename?: "FactsheetLeftAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
    rightUpper?: {
      __typename?: "FactsheetLargeAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
    lower?: {
      __typename?: "FactsheetLargeAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    } | null;
  } | null;
  teasers?: Array<{
    __typename?: "TeaserAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  }> | null;
  keyVisuals?: Array<{
    __typename?: "KeyVisualAsset";
    fileType: string;
    imageHeight?: number | null;
    imageWidth?: number | null;
    id: string;
    name: string;
    uuid?: string | null;
    file: { __typename?: "DjangoFileType"; size: number; url: string };
    factsheetLeft?: {
      __typename?: "FactsheetLeft";
      x: number;
      y: number;
      width: number;
      height: number;
      rotation: number;
    } | null;
  }> | null;
  gallery?: Array<{
    __typename?: "GalleryPicture";
    index: number;
    asset: {
      __typename?: "GalleryAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    };
  }> | null;
  files?: Array<{
    __typename?: "PropertyFile";
    displayNameDe: string;
    displayNameFr: string;
    displayNameIt: string;
    displayNameEn: string;
    asset: {
      __typename?: "RawAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    };
  }> | null;
};

export type PropertyMediaPageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PropertyMediaPageQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    groups: Array<{ __typename?: "Group"; name: string }>;
  };
  property: {
    __typename?: "Property";
    portfolio?: Portfolio | null;
    titleDe: string;
    titleFr: string;
    titleIt: string;
    latestUpdate: {
      __typename?: "LatestUpdate";
      at: any;
      by?: { __typename?: "User"; firstName: string; lastName: string } | null;
    };
    acquisition?: {
      __typename?: "Acquisition";
      factsheetImages: {
        __typename?: "FactsheetPositions";
        leftUpper?: {
          __typename?: "FactsheetLeftAsset";
          fileType: string;
          imageHeight?: number | null;
          imageWidth?: number | null;
          id: string;
          name: string;
          uuid?: string | null;
          file: { __typename?: "DjangoFileType"; size: number; url: string };
          factsheetLeft?: {
            __typename?: "FactsheetLeft";
            x: number;
            y: number;
            width: number;
            height: number;
            rotation: number;
          } | null;
        } | null;
        rightUpper?: {
          __typename?: "FactsheetLargeAsset";
          fileType: string;
          imageHeight?: number | null;
          imageWidth?: number | null;
          id: string;
          name: string;
          uuid?: string | null;
          file: { __typename?: "DjangoFileType"; size: number; url: string };
          factsheetLeft?: {
            __typename?: "FactsheetLeft";
            x: number;
            y: number;
            width: number;
            height: number;
            rotation: number;
          } | null;
        } | null;
        lower?: {
          __typename?: "FactsheetLargeAsset";
          fileType: string;
          imageHeight?: number | null;
          imageWidth?: number | null;
          id: string;
          name: string;
          uuid?: string | null;
          file: { __typename?: "DjangoFileType"; size: number; url: string };
          factsheetLeft?: {
            __typename?: "FactsheetLeft";
            x: number;
            y: number;
            width: number;
            height: number;
            rotation: number;
          } | null;
        } | null;
      };
    } | null;
    factsheetImages: {
      __typename?: "FactsheetPositions";
      leftUpper?: {
        __typename?: "FactsheetLeftAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      } | null;
      rightUpper?: {
        __typename?: "FactsheetLargeAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      } | null;
      lower?: {
        __typename?: "FactsheetLargeAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      } | null;
    };
    teasers?: Array<{
      __typename?: "TeaserAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    }> | null;
    keyVisuals?: Array<{
      __typename?: "KeyVisualAsset";
      fileType: string;
      imageHeight?: number | null;
      imageWidth?: number | null;
      id: string;
      name: string;
      uuid?: string | null;
      file: { __typename?: "DjangoFileType"; size: number; url: string };
      factsheetLeft?: {
        __typename?: "FactsheetLeft";
        x: number;
        y: number;
        width: number;
        height: number;
        rotation: number;
      } | null;
    }> | null;
    gallery?: Array<{
      __typename?: "GalleryPicture";
      index: number;
      asset: {
        __typename?: "GalleryAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      };
    }> | null;
    files?: Array<{
      __typename?: "PropertyFile";
      displayNameDe: string;
      displayNameFr: string;
      displayNameIt: string;
      displayNameEn: string;
      asset: {
        __typename?: "RawAsset";
        fileType: string;
        imageHeight?: number | null;
        imageWidth?: number | null;
        id: string;
        name: string;
        uuid?: string | null;
        file: { __typename?: "DjangoFileType"; size: number; url: string };
        factsheetLeft?: {
          __typename?: "FactsheetLeft";
          x: number;
          y: number;
          width: number;
          height: number;
          rotation: number;
        } | null;
      };
    }> | null;
  };
};

export type UpdatePropertyAssetsMutationVariables = Exact<{
  data: PropertyAssetPartial;
}>;

export type UpdatePropertyAssetsMutation = {
  __typename?: "Mutation";
  updatePropertyAssets:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | {
        __typename?: "PropertyAsset";
        acquisitionFactsheetImages?: {
          __typename?: "FactsheetPositions";
          leftUpper?: {
            __typename?: "FactsheetLeftAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          } | null;
          rightUpper?: {
            __typename?: "FactsheetLargeAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          } | null;
          lower?: {
            __typename?: "FactsheetLargeAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          } | null;
        } | null;
        projectFactsheetImages?: {
          __typename?: "FactsheetPositions";
          leftUpper?: {
            __typename?: "FactsheetLeftAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          } | null;
          rightUpper?: {
            __typename?: "FactsheetLargeAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          } | null;
          lower?: {
            __typename?: "FactsheetLargeAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          } | null;
        } | null;
        teasers?: Array<{
          __typename?: "TeaserAsset";
          fileType: string;
          imageHeight?: number | null;
          imageWidth?: number | null;
          id: string;
          name: string;
          uuid?: string | null;
          file: { __typename?: "DjangoFileType"; size: number; url: string };
          factsheetLeft?: {
            __typename?: "FactsheetLeft";
            x: number;
            y: number;
            width: number;
            height: number;
            rotation: number;
          } | null;
        }> | null;
        keyVisuals?: Array<{
          __typename?: "KeyVisualAsset";
          fileType: string;
          imageHeight?: number | null;
          imageWidth?: number | null;
          id: string;
          name: string;
          uuid?: string | null;
          file: { __typename?: "DjangoFileType"; size: number; url: string };
          factsheetLeft?: {
            __typename?: "FactsheetLeft";
            x: number;
            y: number;
            width: number;
            height: number;
            rotation: number;
          } | null;
        }> | null;
        gallery?: Array<{
          __typename?: "GalleryPicture";
          index: number;
          asset: {
            __typename?: "GalleryAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          };
        }> | null;
        files?: Array<{
          __typename?: "PropertyFile";
          displayNameDe: string;
          displayNameFr: string;
          displayNameIt: string;
          displayNameEn: string;
          asset: {
            __typename?: "RawAsset";
            fileType: string;
            imageHeight?: number | null;
            imageWidth?: number | null;
            id: string;
            name: string;
            uuid?: string | null;
            file: { __typename?: "DjangoFileType"; size: number; url: string };
            factsheetLeft?: {
              __typename?: "FactsheetLeft";
              x: number;
              y: number;
              width: number;
              height: number;
              rotation: number;
            } | null;
          };
        }> | null;
      };
};

export type CreatePropertyMutationVariables = Exact<{
  data: PropertyInput;
}>;

export type CreatePropertyMutation = {
  __typename?: "Mutation";
  createProperty:
    | {
        __typename: "OperationInfo";
        messages: Array<{
          __typename?: "OperationMessage";
          code?: string | null;
          field?: string | null;
          kind: OperationMessageKind;
        }>;
      }
    | { __typename?: "Property"; id: string };
};
